module controllers {
    export module master {
        interface IShipperUpdateScope extends ng.IScope {
        }

        interface IShipperUpdateParams extends ng.ui.IStateParamsService {
            shpId: number;
        }

        export class shipperUpdateCtrl {

            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                '$anchorScroll',
                '$uibModal',
                'bsLoadingOverlayService',
                '$stateParams',
                'entityService',
                '$state',
                '$timeout',
                '$transitions',
                'classificationValueService',
                'documentRepositoryService',
                'lookupService',
                "supplierService",
                "localeService",
                'shipperService',
                'authorisedDealerService',
                'routeCodeService',
                'userAccountService',
                'notifyPartyService',
            ];


            shpId: number;

            breadCrumbDesc: string;

            isLoadingDetail: boolean = false;
            showAll: boolean = false;

            selectedTab: number = 0;

            documentRepository: interfaces.documentManagement.IDocumentRepository;
            IsDisabled: boolean = false;

            shipper: interfaces.master.IShipperMaster;
      
            constructor(
                private $scope: IShipperUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService,
                private $stateParams: IShipperUpdateParams,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public lookupService: interfaces.applicationcore.ILookupService,
                private supplierService: interfaces.master.ISupplierService,
                private localeService: interfaces.applicationcore.ILocaleService,
                private shipperService: interfaces.master.IShipperService,
                public authorisedDealerService: interfaces.tariff.IAuthorisedDealerService,
                public routeCodeService: services.master.routeCodeService,
                public userAccountService: services.applicationcore.userAccountService,
                public notifyPartyService: services.master.notifyPartyService,
            ) {

                this.shpId = $stateParams.shpId;

                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.LoadShipper(this.shpId));

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'shp.update'
                },
                    () => {
                        return $q.all(loadPromises);
                    });
                
            }

            loadRoutes() {
                return this.routeCodeService.getDropdownListByCountry(this.shipper.EntityId, true, this.shipper.CountryId, null).query({
                }, (resultList) => {
                }).$promise;
            }

            loadResponsibleUsers(searchText: string) {
                return this.userAccountService.getDropdownList(searchText, this.shipper.EntityId).query({
                }, (resultList) => {
                }).$promise;
            }

            loadNotifyParties() {
                return this.notifyPartyService.getDropdownList().query({
                    ownerEntityId: this.shipper.EntityId
                }, (resultList) => {
                }).$promise;
            }

            LoadSuppliers(searchText: string) {
             
                return this.supplierService.getSupplierDropdown(this.shipper.EntityId, searchText).query({
                }, (resultList) => {
                }).$promise;
            }

            LoadShipper(Id : number)
            {
                return this.shipperService.getShipper().query({ Id: Id }, (data: interfaces.master.IShipperMaster) => {

                    this.shipper = data;

                    this.breadCrumbDesc = this.shipper.CompanyCode;

                    this.isLoadingDetail = true;
                    this.$timeout(() => {
                        //this.gvwInvoices.data = this.payment.InvoiceList;
                        //this.gvwLC.data = this.payment.LetterOfCreditList;
                        //this.loadControls();
                        this.isLoadingDetail = false;
                    });
                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            Close() {
                this.$state.go("^");
            }

            saveShipper() {

                
                return this.shipperService.save().save(this.shipper, (data: interfaces.applicationcore.IMessageHandler) => {
                     this.generalService.displayMessageHandler(data);
                                   
                  
                 }, (errorResponse) => {
                     this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
 
                 }).$promise;
             };
 
              Save() {
                 this.bsLoadingOverlayService.wrap({
                     referenceId: 'shp.update'
                 }, () => {
                     return this.saveShipper().then((result) => {
                         this.generalService.displayMessageHandler(result);
                         if (!result.HasErrorMessage) {
                             this.$timeout(() => {
                                 this.bsLoadingOverlayService.wrap({
                                     referenceId: 'pay.update'
                                 }, () => {
                                     return this.LoadShipper(this.shpId);
                                 });
                             });
                         }
                     });
                 });
             }
 
             SaveClose() {
                 this.bsLoadingOverlayService.wrap({
                     referenceId: 'shp.update'
                 }, () => {
                     return this.saveShipper().then(() => {
                         this.Close();
                     }, (failureData) => {
                         this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                         this.$anchorScroll("topAnchor");
                     });
                 });
             }

          
        };

        angular.module("app").controller("shipperUpdateCtrl", controllers.master.shipperUpdateCtrl);
    }
}
